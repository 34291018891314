import React from 'react'

import OurMissionBackgroundImage from '../../../images/headers/ourmission.jpg'

import Template from './Template'

import HospitalInfo from './HospitalInfo'
import CommunityInfo from './CommunityInfo'
import SchoolInfo from './SchoolInfo'

const pages = {
  school: () => <Template
    info={SchoolInfo}
    headerImage={OurMissionBackgroundImage}
    heroText='KAVI Violence Prevention in `Schools`'
    apiURL='https://staging-backend.kavibrooklyn.org/api/programs_school?_format=json'
          />,
  hospital: () => <Template
    info={HospitalInfo}
    headerImage={OurMissionBackgroundImage}
    heroText='Kings County `Hospital-Based` Violence Intervention'
    apiURL='https://staging-backend.kavibrooklyn.org/api/programs_hospital?_format=json'
            />,
  community: () => <Template
    info={CommunityInfo}
    headerImage={OurMissionBackgroundImage}
    heroText='KAVI `Community` Programming'
    apiURL='https://staging-backend.kavibrooklyn.org/api/programs_community?_format=json'
             />
}

export default pages
