import React from 'react'
import classNames from 'classnames'

import Hero from '../../templates/Hero'
import Header from '../../templates/Header'
import Loader from '../../templates/Loader';
import PlaceholderParagraph from '../../templates/PlaceholderParagraph';
import { KaviFooter } from '../../templates/Footer'

let HeroText = <React.Fragment><span>The</span> Team</React.Fragment>

import TheTeamBackgroundImage from '../../../images/headers/theteam.jpg'

import UIkit from 'uikit'

import axios from 'axios'

import PlaceholderImage from '../../templates/PlaceholderImage'

var getClosest = function (elem, selector) {
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}

	return null;
}

const TeamMemberCard = (props) => {
	let iconClassName = classNames(
		'uk-position-center-right',
		'fas fa-chevron-right',
		'team-member-icon',
		{ 'rotate-90deg': (props.index===0) } // default rotate first item chevron
	)

	return (
		<li key={props.index} className='team-member'>
			<a className='uk-accordion-title' href='#'>

				{
					(props.image ? <img src={props.image} /> : <Loader inline={true}/>)
				}

				<div className='team-member-badge uk-position-relative'>
					<span className='team-member-name'> {props.name || 'Loading...'} </span>

					<span className='team-member-position'> {props.position || 'Loading...'} </span>

					<span className={iconClassName} />
				</div>
			</a>

			<div className='uk-accordion-content uk-padding uk-text-small'>
				{ props.bio ? <p dangerouslySetInnerHTML={{__html: (props.bio)}}></p> : <PlaceholderParagraph />}
			</div>
		</li>
	)
}

class TheTeam extends React.Component {
	constructor(props) {
		super(props)

		this.state = { loading: true, teamMembers: [<TeamMemberCard />] }
	}

  componentDidMount() {
    UIkit.util.on('#team-accordion', 'show', function (x, y) {
      let liElement = getClosest(x.target, '.team-member')
      liElement.querySelector('.team-member-icon').classList.add('rotate-90deg')
    });

    UIkit.util.on('#team-accordion', 'hide', function (x, y) {
      let liElement = getClosest(x.target, '.team-member')
      liElement.querySelector('.team-member-icon').classList.remove('rotate-90deg')
    });

		// todo: axios call
		axios.get('https://staging-backend.kavibrooklyn.org/api/team_members?_format=json')
			.then( (res) => {
				if (res.status === 200) {
					let items = res.data.map( (item, index) => ({
						image: item.field_team_member_image,
						name: item.team_member_name,
						position: item.team_member_role,
						bio: item.team_member_bio
					}))

					this.setState({ loading: false, teamMembers: items })
					UIkit.accordion('#team-accordion').toggle();
				}
			}, (err) => console.log(err))
  }

  render() {
    return (
      <React.Fragment>

        {/* Header */}
        <Header backgroundImage={TheTeamBackgroundImage} height='uk-height-large'>

          <Hero heroText={HeroText} height='100%'/>
        </Header>

        <div className='uk-section uk-section-muted kavi-gradient-1'>
          <div className='uk-container uk-container-small'>

					{ this.state.loading ? <Loader heightLarge={true} /> :

						<ul id='team-accordion' data-uk-accordion='active: 0;'>

							{
								this.state.teamMembers.map( (member, index) => (
									<TeamMemberCard key={index} index={index} {...member} />
								))
							}

						</ul>

					}

          </div>
        </div>

        {KaviFooter}

      </React.Fragment>
    )
  }
}

export default TheTeam
