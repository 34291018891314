import React from 'react'

import Header from '../../templates/Header'
import Hero from '../../templates/Hero'
import { KaviFooter } from '../../templates/Footer'

import OurMissionBackgroundImage from '../../../images/headers/ourmission.jpg'

import axios from 'axios'

const HeroText = 'Testimonials'

const Testimonial = (props) => (
  <div className="testimonial uk-margin-large">
    <div className="testimonial-body uk-text-bold uk-padding-small">
      {props.body}
    </div>
    <div className="testimonial-attribution">
      <div className="testimonial-arrow">
      </div>

      <div className='uk-display-inline-block' style={{padding: '.3rem'}}>
        <span className="testimonial-person uk-text-uppercase kavi-letter-spacing-5-10">{props.attribution}</span><br/>
        {props.subtitle &&
          <React.Fragment>
            <hr style={{marginTop: '.1rem', marginBottom: '.1rem'}}/>

            <span className="testimonial-subtitle uk-text-uppercase kavi-letter-spacing-1">{props.subtitle}</span>
          </React.Fragment>
        }
      </div>

    </div>
  </div>
)

const ExampleTestimonial = {
  body: "These guys are great",
  attribution: 'Al Gore',
  subtitle: 'Emperor of the Internet'
}

export default class Testimonials extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      'testimonials': [ExampleTestimonial]
    }
  }

  componentDidMount() {
    axios.get('https://staging-backend.kavibrooklyn.org/api/testimonials?_format=json')
      .then( (res) => {
        if (res.status === 200) {
          let items = res.data.map( (item,index) => ({
            body: item.field_testimonial_quote,
            attribution: item.field_testimonial_source_name,
            subtitle: item.field_testimonial_subheading
          }))

          this.setState({ testimonials: items })
        }
      }, (err) => console.log(err))
  }

  render() {
    return (
      <React.Fragment>
        {/* Header */}
        <Header backgroundImage={OurMissionBackgroundImage} height='uk-height-large'>

          <Hero heroText={HeroText} height='100%'/>
        </Header>

        <div className='uk-section kavi-gradient-1' style={{background: 'rgb(240,240,240)'}}>
          <div className='uk-container'>
            {
              this.state.testimonials.map( (testimonial, index) => <Testimonial {...testimonial} />)
            }
          </div>
        </div>

        {/* Footer */}
        {KaviFooter}
      </React.Fragment>
    )
  }
}
