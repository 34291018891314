import React from 'react'

import classNames from 'classnames'

import Hero from '../templates/Hero'
import Header from '../templates/Header'
import Loader from '../templates/Loader';
import BlockquoteCTA from '../templates/BlockquoteCTA'
import { KaviFooter } from '../templates/Footer'
import IntroMission from '../pages/home/IntroMission'
import Intervention from './Intervention'
import Featured from './Featured'

import UIkit from 'uikit'

{/*import HeaderBackgroundImage from '../../images/headers/home.jpg'*/}

import axios from 'axios'

export default class Home extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      hero: {
        text: 'Loading...',
        cta: {
          text: 'Loading...',
          url: ''
        }
      },
      splash: {
        text: 'Loading...',
        cta: {
          text: 'Loading...',
          url: ''
        }
      }
    }
  }

  componentDidMount() {
    this._isMounted = true
    axios.get('https://staging-backend.kavibrooklyn.org/api/home?_format=json')
      .then( (res) => {
        let data = res.data[0]

        let banner_text = data.banner_text
        if (this._isMounted) {
        this.setState({
          loading: false,
          hero: {
            background: data.banner_image,
            text: data.banner_text,
            cta: {
              text: data.banner_cta_link_text,
              url: data.banner_cta_link
            }
          },
          splash: {
            text: data.splash_text,
            cta: {
              text: data.splash_cta_text,
              url: data.splash_cta_url
            }
          }
        })
      }
      }, (err) => console.log(err));

  }

  render() {
    return (
      <React.Fragment>
        {/* Header */}
        <Header backgroundImage={this.state.hero.background} height='uk-height-1-1'>

          {
            this.state.loading ? <Loader fullHeight={true} /> : <Hero heroText={this.state.hero.text} ctaText={this.state.hero.cta.text} ctaUrl={this.state.hero.cta.url} heightViewport='offset-top: true;'/>
          }
        </Header>

        {/* KAVI Mission Placeholder */}
        <IntroMission />

        {/* Intervention*/}
        <Intervention />

        {/* Blockquote CTA */}
        <BlockquoteCTA text={this.state.splash.text} ctaText={this.state.splash.cta.text} ctaUrl={this.state.splash.cta.url} />

        {/* Featured */}
        <Featured />

        {/* Footer */}
        {KaviFooter}


      </React.Fragment>
    )
  }
}
