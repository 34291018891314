import React from 'react'
import classNames from 'classnames'

export default (props) => {

  let className = classNames(
    'home-page-header',
    props.className,
    props.height,
    'uk-padding-remove-vertical',
    'uk-background-cover'
  )

  return (
    <div className={className} data-src={props.backgroundImage} data-uk-img>
      {props.children}
    </div>
    )
}
