import React from 'react'
import classNames from 'classnames'

export default (props) => {

	let containerClasses = classNames(
		'loader-container',
		props.fullHeight ? 'full-height': null,
		props.heightLarge ? 'uk-height-large': null,
		props.inline ? 'inline' : null
	);

	return (
		<div className={containerClasses}>
			<div className="lds-css ng-scope">
				<div style={{ width: "100%", height:"100%" }} className="lds-rolling">
					<div></div>
				</div>
			</div>
		</div>
	);

}
