import React from 'react'
import Image1 from '../../../images/dr-rob-gore-in-kavi-session.jpg'
import Image2 from '../../../images/kavi-students-gathered.jpg'

export default (props) => (
  <div>
    <h2 className="uk-padding uk-padding-remove-vertical uk-text-center">KAVI stands at the bedsides and in family waiting rooms to assist with trauma recovery services.</h2>
    <hr className="uk-divider-icon uk-padding uk-padding-remove-vertical" />
    <div className="uk-padding" data-uk-grid>

      <div className="uk-width-1-2@m">
      <img src={Image1} className="uk-border-rounded" alt="Dr. Rob Gore in a KAVI session." />
      </div>
      <div className="uk-width-1-2@m">
      <p>On a typical day at Kings County Hospital Center (KCHC), multiple victims of gunshot wounds and other trauma are rushed into emergency surgery as doctors and nurses and staff rush in to try save their lives.
      Their families stream into waiting rooms frightened and desperate to know what happened and what lies ahead for their loved one.
      <br/><br/>The violence is pervasive. A Level 1 Trauma Center, KCHC sees some of the highest volumes of pediatric, adolescent, and adult trauma cases in NYC. Staff routinely rush from patient and family to patient and family throughout the day.
      These scenes can nearly overwhelm the sometimes stretched-thin hospital staff.</p>
      </div>

      <div className="uk-width-1-2@m">
      <p>The work of helping the victims and their families, and even the hospital staff itself, recover from trauma and avoid another violent incident is the work of KAVI’s Violence Intervention Programs. Originally begun as a hospital-based youth violence intervention program by KAVI founder Dr. Rob Gore, an ER physician at Kings County Hospital Center, the program has grown into a multi-layered approach that provides linkages to a full range of services to prevent violence and bring about healing throughout the community.
      <br/><br/>KAVI program staff provides crucial follow-up services and referrals to ongoing support to address the underlying causes of violence, the trauma associated with injury, the coping support for patients and the wrap-around services victims need to recover, build resiliency, and move beyond the trauma of their experience.</p>
      </div>
      <div className="uk-width-1-2@m">
      <img src={Image2} className="uk-border-rounded" alt="KAVI students gathered." />
      </div>

    </div>
    <hr className="uk-divider-icon" />

    <div className="uk-padding" data-uk-grid>
      <div className="uk-width-1-1@m">
        <p>KAVI’s violence intervention program is valuable and unique because it supports youth who are both victims <b>and</b> perpetrators of violence and trains and prepares them to return to the community safely and healthy so they can thrive.</p>
        <p>Our work helps young people...
          <ul>
            <li>Cope with their trauma,</li>
            <li>Address and de-escalate the conflicts in their lives, and</li>
            <li>Become active young leaders, working to build a safer, stronger community.</li>
          </ul>
        </p>
        <p>Our violence prevention program is a partnership with the Kings County Hospital Emergency and Trauma Departments and <b>Cure Violence, Inc.</b> and includes training all hospital staff in the delicate but indispensable work of <b>Trauma Informed Care</b>.  Our violence intervention specialists work with the young people and their families to address their physical and mental health following an incident of violence and help create a plan for each young person and their family to prioritize their health and well-being when they return to the community.</p>
        <p>We strongly believe that solutions to public safety and violence will come directly from the communities most impacted.</p>
      </div>
    </div>
  </div>
)
