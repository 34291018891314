import React from 'react'

import Header from '../../templates/Header'
import Hero from '../../templates/Hero'
import { KaviFooter } from '../../templates/Footer'

import OurWorkCard from '../../templates/OurWorkCard'

import UIkit from 'uikit'

let HeroText = <React.Fragment><span>Work</span> With Us</React.Fragment>

import TheTeamBackgroundImage from '../../../images/headers/ourmission.jpg'

const ExampleCard = {
  title: 'reiciendis voluptatibus maiores',
  summary: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.',
  description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
  applyText: 'Harum',
  applyUrl: 'https://example.com',
  image: require('../../../images/cards/ourwork/school.jpg')
}

var getClosest = function (elem, selector) {
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}

	return null;
}

export default class Template extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cards: [ExampleCard]
    }
  }

  componentDidMount() {
    UIkit.util.on('#ourwork-accordion', 'show', function (x, y) {
      let liElement = getClosest(x.target, '.ourwork-card')
      liElement.querySelector('.ourwork-card-icon').classList.add('rotate-90deg')

      liElement.querySelector('.readmore').classList.add('readmore-less')
    });

    UIkit.util.on('#ourwork-accordion', 'hide', function (x, y) {
      let liElement = getClosest(x.target, '.ourwork-card')
      liElement.querySelector('.ourwork-card-icon').classList.remove('rotate-90deg')

      liElement.querySelector('.readmore').classList.remove('readmore-less')
    });
  }

  render() {
    return (
      <React.Fragment>
      {/* Header */}
      <Header backgroundImage={TheTeamBackgroundImage} height='uk-height-large'>

        <Hero heroText={HeroText} height='100%'/>
      </Header>

       <div className='uk-section kavi-gradient-1' style={{background: 'rgb(240,240,240)'}}>
          <div className='uk-container'>
            <ul id='ourwork-accordion' data-uk-accordion>
            {/* community engager */}
            <li className='ourwork-card'>
              <a className='uk-accordion-title' href='#'>
                <div className='uk-accordion-title-container'>
                <div className='ourwork-preview'>
                  <div className='ourwork-preview-title'>Community Engager</div>

                  <div className='ourwork-preview-content'>
                    <p>
                    KAVI is seeking an on-call Community Engager to cultivate
            relationships with community members, support community-based programs and events, manage
            volunteers, and more.
                    </p>
                  </div>
                </div>

                </div>

                <div className='ourwork-card-footer'>
                <span>
                  <span className='readmore' />
                  <span className='fas fa-chevron-right ourwork-card-icon uk-margin-small-left' />
                </span>
                </div>

                </a>

                <div className='uk-accordion-content uk-padding uk-text-small uk-margin-remove-top'>
                <div>
                  <h3>About Kings Against Violence Initiative (KAVI)</h3>
                  <p>Kings Against Violence Initiative, Inc. (KAVI) is a nonprofit 501(c)(3) youth-serving organization
                    with a mission to prevent and eliminate interpersonal violence from the lives of young people through
                    advocacy, peer leadership, community mobilization, and social justice.</p>
                    <p>KAVI operates youth violence interventions and prevention programs in Kings County Hospital,
                    partner schools, and within the Central Brooklyn community. We also work with various partners to
                    do community strengthening and healing programs.</p>

                  <h3>Role description</h3>
                  <p>Kings Against Violence Initiative (KAVI) seeks an on-call Community Engager to cultivate
            relationships with community members, support community-based programs and events, manage
            volunteers, and more. Previous work done by KAVI demonstrates that violence can be dramatically
            reduced when a partnership of community members, clinicians, and social service providers directly
            engages with the small and active number of care participants. The program communicates a moral
            message from community representatives that violence is a public health issue, that any future violence
            will predict certain consequences and recidivism, and an offer of help from social service providers for
            those who want it.</p>

                  <p>The Community Engagers position will work with partner agencies, social service providers, and
            community groups to create a unique support structure tailored to KAVI. The Community Engagers
            will work internally and externally to assess needs and direct services as needed.</p>

                  <ul>
                    <li>The Community Engagers will serve as the community's voice, ears, and eyes. Community
              Engagers will advocate for change within New York City’s least-resourced communities,
              aiming to resolve issues like poor access to social services, violence, and other manifestations of
              oppression, poverty, and racist policies and practices. KAVI seeks committed, energetic, and
              creative Community Engagers to engage with residents to create change agents/Social
              Engineers.</li>
              <li>The Community Engagers will work under the direction of the Community Liaison to create
              opportunities for community engagement on issues relating to KAVI’s reform agenda and to
              further develop its relationships with neighboring Community-Based Organizations (CBOs).</li>
              <li>The candidate will be able to demonstrate an excellent ability to communicate with both
              organizations and residents. They will be willing and able to work independently toward
              shared team goals, primarily in community settings. They will be relationship-builders familiar
              with social media and other online tools for community engagement.</li>
              <li>They will be dynamic, passionate, organized, collaborative, willing and able to share ideas, and
              comfortable working in a fast-paced and unpredictable environment. Candidates will be asked
              to work outside regular business hours, evenings and/or weekends.</li>
              <li>Community Engagers will report to weekly brainstorming sessions (held via Zoom or
              in-person) to discuss upcoming events and outcomes; during these sessions, Engagers will be
              tasked to present ideas to the group and seek feedback. The Community Engagers will be
              tasked to work both independently and collaboratively.</li>
                  </ul>

                  <h3>Responsibilities</h3>
                  <ul>
                    <li>Support opportunities for engaging community members in KAVI’s
                    Anti-Violence reform work, including street outreach, attending community events, and
                    convening client advisory boards and community roundtables.</li>
                    <li>Using social media and other digital tools to support community engagement and organizing.</li>
                    <li>Representing KAVI at community events.</li>
                    <li>Recruit and train community event volunteers for various tasks as needed.</li>
                    <li>Work flexible hours outside normal business hours and on weekends, as required.</li>
                    <li>Act as an advocate for the community to elected officials and large institutions (schools,
                    hospitals, etc.)</li>
                    <li>Maintain rich relationships with like-minded CBOs to assist in achieving desired program
                    outcomes within the desired timeframe for our patients.</li>
                    <li>Advocate with service providers or other parties on behalf of participants.</li>
                    <li>Work cooperatively with elected officials, organizations, institutions, and resident agencies to
                    coordinate services.</li>
                    <li>Collect client information as defined by KAVI programs to accurately assess the community's
                    needs.</li>
                    <li>Identify possible solutions to client's complex case scenarios.</li>
                    <li>Engage with stakeholders and partners to support the needs of clients.</li>
                    <li>Other duties as assigned.</li>
                  </ul>



                  <h3>Traininig & Qualifications</h3>
                  <ul>
                    <li>Desire to work within Communities of Color</li>
            <li>Desire to engage community members</li>
            <li>Willingness to participate in professional learning opportunities</li>
            <li>Ability to foster constructive and respectful working relationships with colleagues and clients
            from diverse backgrounds, including those from racially diverse, underserved, and unserved
            communities.</li>
            <li>Familiarity with social media platforms (Instagram, Twitter, TikTok) and other tools for online
            engagement.</li>
            <li>Strong communication and collaboration skills.</li>
            <li>Basic computer skills (email, internet, Microsoft Office, Google Suite, and communication
            tools such as Zoom and Microsoft Teams) are preferred, but not required.</li>
            <li>Ability to work collaboratively and creatively to accomplish shared goals.</li>
            <li>Strong commitment to equity and social justice.</li>
            <li>Ability to work non-traditional week and weekend hours.</li>
            <li>Proficiency in languages other than English is desirable, but optional.</li>
            <li>Excellent verbal communication skills.</li>
            <li>Good writing skills and the ability to maintain correspondence with various organizations and
            business partners.</li>
            <li>Highly developed ability for problem-solving.</li>
            <li>Willingness to learn crisis intervention, restorative justice principles and trauma-informed
            practices.</li>
            <li>Willingness to incorporate feedback into future projects.</li>
            <li>Demonstrates adaptability to various duties, changing from one task to another of a different
            nature without loss of efficiency or composure.</li>
            <li>Ability to negotiate and resolve conflict between dissenting parties.</li>
            <li>Commitment to maintaining confidentiality as needed.</li>
            <li>Youth development experience is a plus.</li>
                  </ul>

                  <h3>Education & Qualifications</h3>
                  <p>Current High School and College students are encouraged to apply.</p>
            <p>Individuals passionate about learning and impacting the community are encouraged to apply.</p>

                  <h3>Compensation</h3>
                  <p>The starting wage for the on-call Community Engagers position is budgeted for $20/hr.</p>

                  <h3>Work Schedule/Location</h3>
                  <p>Part-time (19 hours max), location varies</p>



                  <h3>How to Apply</h3>
                  <p>Please send your resume to <a href="mailto:HR@kavibrooklyn.org">HR@kavibrooklyn.org</a> and include “Community Engager” in the subject line.</p>

                  <sub><i>KAVI is an equal opportunity employer and is committed to maintaining a work and learning environment
            free from discrimination based on race, color, religion, national origin, pregnancy, gender identity, sexual
            orientation, marital/civil union status, ancestry, place of birth, age, citizenship status, veteran status, political
            affiliation, genetic information or disability, as defined and required by state and federal laws. KAVI is an
            Equal Opportunity Employer and complies with the Americans with Disabilities Act.
            </i></sub>
                </div>
                <div className='uk-flex uk-flex-right uk-flex-middle uk-margin-small-top'>
                  <a className='uk-button uk-button-default' href='mailto:HR@kavibrooklyn.org?subject=Community Engager'>Apply now<span className='fas fa-external-link-alt uk-margin-small-left' /></a>
                </div>

                </div>
                  </li>
            {/* peer facilitator */}
            <li className='ourwork-card'>
              <a className='uk-accordion-title' href='#'>
                <div className='uk-accordion-title-container'>

                <div className='ourwork-preview'>
                  <div className='ourwork-preview-title'>KAVI Peer Leader/Peer Facilitator</div>

                  <div className='ourwork-preview-content'>
                    <p>
                      KAVI is seeking qualified Peer Leaders. Peer Leaders will co-lead KAVI’s School and Community
              intervention programs. Peer leaders will facilitate programming for KAVI youth development
              programs.
                    </p>
                  </div>
                </div>

                </div>

                <div className='ourwork-card-footer'>
                <span>
                  <span className='readmore' />
                  <span className='fas fa-chevron-right ourwork-card-icon uk-margin-small-left' />
                </span>
                </div>

                </a>

                <div className='uk-accordion-content uk-padding uk-text-small uk-margin-remove-top'>
                <div>
                  <h3>About Kings Against Violence Initiative (KAVI)</h3>
                  <p>Kings Against Violence Initiative, Inc. (KAVI) is a nonprofit, 501(c)(3) youth-serving organization
            with a mission to prevent and eliminate interpersonal violence from the lives of young people through
            advocacy, peer leadership, community mobilization, and social justice.</p>
            <p>KAVI promotes community wellness and healing by operating high-impact youth violence prevention
            and intervention programs in Central Brooklyn. Our programs take place in hospital-based, school,
            and community settings. We also work with a broad range of partners to do community strengthening
            and healing programs.</p>

                  <h3>Role description</h3>
                  <p>KAVI is seeking qualified Peer Leaders. Peer Leaders will co-lead KAVI’s School and Community
                  intervention programs. Peer leaders will facilitate programming for KAVI youth development
                  programs, additionally providing administrative duties. Peer Leaders will engage with KAVI’s
                  leadership as thought partners for programming.</p>
                  <p>We are looking for energetic, out-going youth ages 16-24 who want to make a difference in the lives of
                  their peers and the community in which they live, are motivated, and strive to learn new things.
                  Peer leaders will join a dynamic team of individuals who work hard, are creative, seize opportunities for
                  growth, and think “out of the box”. Peer Facilitators are vital to our organization and we foster
                  opportunities for professional growth.</p>

                  <h3>Responsibilities</h3>
                  <ul>
                    <li>Facilitate program activities</li>
            <li>Coach youth to adapt positive behaviors</li>
            <li>Model KAVI’s anti-violence values and support positive youth development traits</li>
            <li>Support KAVI’s Social Media postings</li>
            <li>Complete program attendance sheets, timesheets and work plans</li>
            <li>Participate in regular team meetings and paid trainings</li>
            <li>Develop positive relationships with peers</li>
                  </ul>



                  <h3>Qualifications</h3>
                  <ul>
                    <li>A passion for the work of community development, youth development, social equity</li>
            <li>A desire for professional growth</li>
            <li>Ability to work collaboratively in a team</li>
            <li>Basic computer skills are a plus but not required</li>
            <li>A basic ability to work independently and or as a team player</li>
            <li>Attention to detail and customer service skills a must</li>
                  </ul>

                  <h3>Work Schedule/Location</h3>
                  <p>Possible shifts vary per site generally, Peer Facilitators work at least 10 hours per week, Monday
            through Friday. Saturday programs operate quarterly.</p>



                  <h3>How to Apply</h3>
                  <p>Please send your resume to <a href="mailto:HR@kavibrooklyn.org">HR@kavibrooklyn.org</a> and include “Peer Facilitator” in the subject line. KAVI offers a comprehensive employee benefits package.</p>

                  <sub><i>KAVI is an equal opportunity employer and is committed to maintaining a work and learning environment free from discrimination
            based on race, color, religion, national origin, pregnancy, gender identity, sexual orientation, marital/civil union status, ancestry, place
            of birth, age, citizenship status, veteran status, political affiliation, genetic information or disability, as defined and required by state
            and federal laws. KAVI is an Equal Opportunity Employer and complies with the Americans with Disabilities Act.
            </i></sub>
                </div>
                <div className='uk-flex uk-flex-right uk-flex-middle uk-margin-small-top'>
                  <a className='uk-button uk-button-default' href='mailto:HR@kavibrooklyn.org?subject=Peer Facilitator'>Apply now<span className='fas fa-external-link-alt uk-margin-small-left' /></a>
                </div>

                </div>
                  </li>



                  {/*<li className='ourwork-card'>
                    <a className='uk-accordion-title' href='#'>
                      <div className='uk-accordion-title-container'>

                      <div className='ourwork-preview'>
                        <div className='ourwork-preview-title'>Check back soon</div></div></div></a>

                          <div className='ourwork-preview-content'>We'll be sharing more information about open roles in the near future.</div>

                         </li>*/}

                </ul>
                </div>
                </div>

        {/* Footer */}
        {KaviFooter}
      </React.Fragment>
    )
  }
}
