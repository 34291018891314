import React from 'react'

import Header from '../../templates/Header'
import Hero from '../../templates/Hero'
import { KaviFooter } from '../../templates/Footer'

import OurWorkCard from '../../templates/OurWorkCard'

import UIkit from 'uikit'
import axios from 'axios'

const ExampleCard = {
  title: 'reiciendis voluptatibus maiores',
  summary: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.',
  description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
  applyText: 'Harum',
  applyUrl: 'https://example.com',
  image: require('../../../images/cards/ourwork/school.jpg')
}

var getClosest = function (elem, selector) {
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem;
	}

	return null;
}

export default class Template extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cards: [ExampleCard]
    }
  }

  componentDidMount() {
    UIkit.util.on('#ourwork-accordion', 'show', function (x, y) {
      let liElement = getClosest(x.target, '.ourwork-card')
      liElement.querySelector('.ourwork-card-icon').classList.add('rotate-90deg')

      liElement.querySelector('.readmore').classList.add('readmore-less')
    });

    UIkit.util.on('#ourwork-accordion', 'hide', function (x, y) {
      let liElement = getClosest(x.target, '.ourwork-card')
      liElement.querySelector('.ourwork-card-icon').classList.remove('rotate-90deg')

      liElement.querySelector('.readmore').classList.remove('readmore-less')
    });

    axios.get(this.props.apiURL)
      .then( (res) => {
        if (res.status===200) {
          let items = res.data.map( (item, index) => ({
            title: item.program_title,
            summary: item.program_summary,
            description: item.program_description,
            applyText: item.program_apply_text,
            applyUrl: item.program_apply_url,
            image: item.program_thumbnail
          }))

          this.setState({ cards: items})
        }
      }, (err) => console.log(err))
  }

  render() {
    return (
      <React.Fragment>
        {/* Header */}
        <Header backgroundImage={this.props.headerImage} height='uk-height-large'>

          <Hero heroText={this.props.heroText} height='100%'/>
        </Header>

        <div className='uk-section kavi-gradient-1' style={{background: 'rgb(240,240,240)'}}>
          <div className='uk-container'>

            {/* Custom Program Information */}
            {React.createElement(this.props.info)}

            <ul id='ourwork-accordion' data-uk-accordion>
              {
                this.state.cards.map( (card, index) => (
                  <OurWorkCard {...card} key={index} />
                ))
              }
            </ul>
          </div>
        </div>

        {/* Footer */}
        {KaviFooter}
      </React.Fragment>
    )
  }
}
