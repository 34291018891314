import React from 'react'
import Image1 from '../../../images/kavi-community-outreach.jpg'
//import SchoolForm from '../../SchoolForm'

export default (props) => (
  <div>
    <h2 className="uk-padding uk-padding-remove-vertical uk-text-center">KAVI provides stability, pathways to safety, and healing for young people.</h2>
      <h3 className="uk-padding uk-padding-remove-vertical uk-text-center">This overview of KAVI’s  Community programs details the range of services KAVI provides. All of our programs are offered at no cost to program participants.</h3>
    <hr className="uk-divider-icon uk-padding uk-padding-remove-vertical" />
    <div className="uk-padding" data-uk-grid>

      <div className="uk-width-1-2@m">
      <img src={Image1} className="uk-border-rounded" alt="Dr. Rob Gore in a KAVI session." />
      </div>
      <div className="uk-width-1-2@m">
          <p>KAVI has developed detailed, evidenced-based programs and workshops to engage community members in the work of preventing violence and helping people heal. We are strengthening and growing partnerships.</p>
      <p>Our programs help community members to...
        <ul>
          <li>Advocate for our youth,</li>
          <li>Understand and practice restorative justice, and</li>
          <li>Participate in collective healing.</li>
        </ul>
      </p>
      </div>

    </div>

      <hr className="uk-divider-icon" />
      <div className="community-services">
      <div className="uk-card uk-margin uk-card-primary">
          <div className="uk-card-body">
          <h3 className="uk-card-title"><i className="fa fa-home uk-margin-small-right"></i> Family Engagement Services</h3>
          <p>KAVI works with Brooklyn residents experiencing a range of needs by providing connections to necessary resources, partner organization, wrap-around services, and virtual and in- person educational and employment workshops. KAVI’s Family Engagement specialist provides support on a range

              of issues including: legal matters; immigration services; housing opportunities; food insecurity resources; and academic issues. Contact us <a>KAVIFamilyPrograms@kavibrooklyn.org</a> for

              more information, or <a>click</a> here to schedule an appointment with

              our Family Engagement Specialist.</p>
          </div>
      </div>

      <div className="uk-card uk-margin uk-card-primary uk-card-body">
          <h3 className="uk-card-title"><i className="fa fa-user-graduate uk-margin-small-right"></i> Education and Employment Services</h3>
          <p>KAVI provides Education & Employment services for Brooklyn residents who

              are looking to progress professionally. KAVI’s Education & Employment Navigator provides services such as college application assistance, linkages to Workforce Development opportunities, job application support, resume and cover letter writing support. Contact us at <a>KAVIEEP@kavibrooklyn.org</a> for more information, or <a>click here</a> to schedule an appointment with our

              Education and Employment Navigator.</p>
      </div>

      <div className="uk-card uk-margin uk-card-primary uk-card-body">
          <h3 className="uk-card-title"><i className="fa fa-hand-holding-heart uk-margin-small-right"></i> Community Engagement and Outreach</h3>
          <p>Are you interested in ending violence in your community? Are you energetic, creative, and looking for an opportunity to develop your leadership skills? Working as a KAVI Community Engager might be the perfect fit for you! KAVI engages the community through outreach, connections to services, and peer-led trauma-informed programs. Contact us at <a>Community@kavibrooklyn.org</a> for more information or to inquire about becoming a KAVI Community Engager.</p>
      </div>
      </div>

    {/*<hr className="uk-divider-icon" />
      <h3 className="uk-padding uk-padding-remove-vertical uk-text-center">Interested in bringing KAVI School programs to your school? Please fill out the following form, and a KAVI staff member will be in touch to follow up on your inquiry.</h3>


      <div className="uk-padding" data-uk-grid>
      <div className="uk-width-1-1@m">
        <SchoolForm />

      </div>
    </div>*/}
  </div>
)
