import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Logo from '../../images/kavi-logo.png'

import Home from '../pages/Home'

import AboutUsOurMission from '../pages/aboutus/OurMission'
import AboutUsTheTeam from '../pages/aboutus/TheTeam'
import AboutUsCareers from '../pages/aboutus/Careers'

import OurWorkTestimonials from '../pages/ourwork/Testimonials'

import InterventionPages from '../pages/ourwork/InterventionPages'

import MediaNewsAndPress from '../pages/media/NewsAndPress'
import MediaBlog from '../pages/media/Blog'

import GetInvolved from '../pages/GetInvolved'

import Page404 from '../pages/404'

import { NavLink as Link, Route, Switch } from 'react-router-dom'

import { withRouter } from 'react-router'

import DonateModal from '../DonateModal'

class CustomLink extends React.Component {
 render() {
   const { location, to, staticContext } = this.props;
   let isActive = (location.pathname === to)

   let className = classNames(
     this.props.className,
     { ['uk-active']: isActive}
   )

   return <li className={className}> <Link children={this.props.children} to={this.props.to} /> </li>
 }
}

CustomLink.propTypes = {
  location: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired
}

const CustomLinkWithRouter = withRouter(CustomLink)

export default (props) => {
  return (
  <div>
    <nav className='uk-navbar-container uk-position-absolute uk-width-expand' data-uk-navbar>
      {/* KAVI Logo */}
      <div className='uk-navbar-left'>

        <a href='#' className='uk-navbar-item uk-width-small' onClick={(e) => e.preventDefault()}>
          <img data-src={Logo} alt='KAVI' width='' height='' data-uk-img />
        </a>

      </div>

      <div className='uk-navbar-right'>
      {/* Social Media Icon Stack */}
      <ul className='uk-navbar-nav uk-navbar-social uk-visible@m'>
      <li>
        <a href='https://www.facebook.com/kingsagainstviolenceinitiative/' target="_blank">
          <span className='fab fa-facebook-square fa-lg'/>
        </a>
      </li>
      <li>
        <a href='https://www.instagram.com/kavibrooklyn/' target="_blank">
          <span className='fab fa-instagram fa-lg'/>
        </a>
      </li>
      <li>
        <a href='https://twitter.com/kavi_brooklyn' target="_blank">
          <span className='fab fa-twitter-square fa-lg'/>
        </a>
      </li>
      </ul>
        <ul className='uk-navbar-nav uk-visible@m'>

          {/* Base Navigation Scheme */}
          <CustomLinkWithRouter to='/'>Home</CustomLinkWithRouter>

          <li>

            <a href='#' onClick={(e) => e.preventDefault()}>About Us</a>

            <div className="uk-navbar-dropdown">

              <ul className="uk-nav uk-navbar-dropdown-nav">
                <CustomLinkWithRouter className='hvr-icon-forward' to='/aboutus/ourmission'>Our Mission<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
                <CustomLinkWithRouter className='hvr-icon-forward' to='/aboutus/theteam'>The Team<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
                <CustomLinkWithRouter className='hvr-icon-forward' to='/aboutus/careers'>Work with Us<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
              </ul>
            </div>
          </li>

          <li>

            <a href='#' onClick={(e) => e.preventDefault()}>Our Work</a>

            <div className="uk-navbar-dropdown">

              <ul className="uk-nav uk-navbar-dropdown-nav">
                <CustomLinkWithRouter className='hvr-icon-forward' to='/ourwork/hospital'>Hospital<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
                <CustomLinkWithRouter className='hvr-icon-forward' to='/ourwork/community'>Community<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
                <CustomLinkWithRouter className='hvr-icon-forward' to='/ourwork/school'>School<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
                <CustomLinkWithRouter className='hvr-icon-forward' to='/ourwork/testimonials'>Testimonials<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
              </ul>
            </div>
          </li>

          <li>

            <a href='#' onClick={(e) => e.preventDefault()}>Media</a>

            <div className="uk-navbar-dropdown">

              <ul className="uk-nav uk-navbar-dropdown-nav">
                <CustomLinkWithRouter className='hvr-icon-forward' to='/media/newsandpress'>News & Press<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter>
                {/* <CustomLinkWithRouter className='hvr-icon-forward' to='/media/blog'>Blog<span className='fas fa-chevron-right uk-margin-small-left hvr-icon' /></CustomLinkWithRouter> */}

              </ul>
            </div>
          </li>

          <CustomLinkWithRouter to='/getinvolved'>Get Involved</CustomLinkWithRouter>

          {/* Donate Button */}
          <li>

            <a href="#" onClick={e=>e.preventDefault()}>
              <button
                className='uk-button uk-button-secondary uk-border-rounded hvr-grow'
                data-uk-toggle="target: #donate-modal"
              >
                Donate
                <span className="fas fa-credit-card uk-margin-small-left"></span>
              </button>
            </a>

          </li>
        </ul>

        {/* Offcanvas Navbar Toggle Button */}
        <ul className='uk-navbar-nav uk-hidden@m'>
          <li>
            <a href="#" onClick={(e) => e.preventDefault()} data-uk-icon='icon: menu; ratio: 1.5;' data-uk-toggle='target: #offcanvas-navbar'/>
          </li>
        </ul>
      </div>

      {/* Offcanvas Navbar (hidden) */}
      <div id='offcanvas-navbar' data-uk-offcanvas='flip: true; overlay: true;'>
        <div className='uk-offcanvas-bar uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-offcanvas-bar-animation uk-offcanvas-slide'>
          <a href='#' className='uk-offcanvas-close' onClick={(e)=>e.preventDefault()} data-uk-close />

          <ul className='uk-nav uk-nav-default uk-nav-center uk-nav-parent-icon' data-uk-nav>
            <CustomLinkWithRouter to='/'>Home</CustomLinkWithRouter>
            {/* <li className='uk-active'> <Link activeClassName='uk-active' to='/'>Home</Link> </li> */}
            <li className='uk-parent'>
              <a href='#' onClick={(e)=>e.preventDefault()}>About Us</a>
              <ul className='uk-nav-sub uk-nav-default' data-uk-nav>
                <CustomLinkWithRouter to='/aboutus/ourmission'>Our Mission</CustomLinkWithRouter>
                <CustomLinkWithRouter to='/aboutus/theteam'>The Team</CustomLinkWithRouter>
                <CustomLinkWithRouter to='/aboutus/careers'>Work With Us</CustomLinkWithRouter>
              </ul>
            </li>
            <li className='uk-parent'>
              <a href='#' onClick={(e)=>e.preventDefault()}>Our Work</a>
              <ul className='uk-nav-sub uk-nav-default' data-uk-nav>
                <CustomLinkWithRouter to='/ourwork/hospital'>Hospital</CustomLinkWithRouter>
                <CustomLinkWithRouter to='/ourwork/community'>Community</CustomLinkWithRouter>
                <CustomLinkWithRouter to='/ourwork/school'>School</CustomLinkWithRouter>
                <CustomLinkWithRouter to='/ourwork/testimonials'>Testimonials</CustomLinkWithRouter>
              </ul>
            </li>

            <li className='uk-parent'>
              <a href='#' onClick={(e)=>e.preventDefault()}>Media</a>
              <ul className='uk-nav-sub uk-nav-default' data-uk-nav>
                <CustomLinkWithRouter to='/media/newsandpress'>News & Press</CustomLinkWithRouter>
                {/* <CustomLinkWithRouter to='/media/blog'>Blog</CustomLinkWithRouter> */}
              </ul>
            </li>

            <CustomLinkWithRouter to='/getinvolved'>Get Involved</CustomLinkWithRouter>
          </ul>

          <div className='uk-navbar-item'>
          <a href="#" onClick={e=>e.preventDefault()}>
            <button
              className='uk-button uk-border-rounded kavi-background-accent-blue kavi-text-white hvr-grow'
              data-uk-toggle="target: #donate-modal"
            >
              Donate
              <span className="fas fa-credit-card uk-margin-small-left"></span>
            </button>
          </a>
          </div>

          <div className='uk-navbar-item uk-flex-top'>
            <ul className='uk-iconnav uk-flex-1'>
              <li> <a href='https://www.facebook.com/kingsagainstviolenceinitiative/' target="_blank"><span className='fab fa-facebook-square fa-2x' /></a> </li>
              <li> <a href='https://www.instagram.com/kavibrooklyn/' target="_blank"><span className='fab fa-instagram fa-2x' /></a> </li>
              <li> <a href='https://twitter.com/kavi_brooklyn' target="_blank"><span className='fab fa-twitter-square fa-2x' /></a> </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <DonateModal />

    {/*  */}

    <Switch>
      <Route exact path='/' component={Home} />

      <Route exact path='/aboutus/ourmission' component={AboutUsOurMission} />
      <Route exact path='/aboutus/theteam' component={AboutUsTheTeam} />
      <Route exact path='/aboutus/careers' component={AboutUsCareers} />

      <Route exact path='/ourwork/testimonials' component={OurWorkTestimonials} />

      <Route exact path='/ourwork/school' component={InterventionPages.school} />
      <Route exact path='/ourwork/hospital' component={InterventionPages.hospital} />
      <Route exact path='/ourwork/community' component={InterventionPages.community} />

      <Route exact path='/media/newsandpress' component={MediaNewsAndPress} />
      {/* <Route exact path='/media/blog' component={MediaBlog} /> */}

      <Route exact path='/getinvolved' component={GetInvolved} />

      <Route component={Page404} />
    </Switch>

  </div>

  )
}
