/**
 * Let's user know that content is loading, but also expands to a certain size
 * to let the user really know that something is going to occupy this space
 * and that it will take up some room.
 * * * * */

import React from 'react';

export default (props) => {

	return(
		<div className="placeholder-paragrah">
			<span>Loading...</span>
		</div>
	);

}
