import React from "react";

import DonateForm from './DonateForm'

const DonateModal = props => {
  return (
    <div id="donate-modal" data-uk-modal>
      <div
        className="uk-modal-dialog uk-modal-body"
        style={{ background: "white" }}
      >
        <DonateForm />
      </div>
    </div>
  );
};

export default DonateModal;
