import React from 'react';

class DonateForm extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {

    return (
      <>
        <h4 className="uk-modal-title uk-text-uppercase uk-text-center kavi-letter-spacing-1 kavi-text-poppin-orange">
          Contribute via GiveLively
        </h4>
        <hr />
        <div id="give-lively-widget" className="gl-simple-donation-widget"></div>

      </>
    )
  }
}

export default DonateForm;
