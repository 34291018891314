import React from 'react'

const API_URL = 'https://via.placeholder.com/'

export default (props) => {
  let url = API_URL

  if (props.x && props.y) {
    url += `${props.x}x${props.y}`
  }

  if (props.text) {
    let text = props.text.replace(' ', '+')
    url += `?text=${text}`
  }

  return <img className={props.className} src={url} width={props.width} height={props.height} />
}
