import React from 'react'

export default (props) => (
	<li className='ourwork-card'>
		<a className='uk-accordion-title' href='#'>
			<div className='uk-accordion-title-container'>

				<img src={props.image} />

				<div className='ourwork-preview'>
					<div className='ourwork-preview-title'>{props.title}</div>

					<div className='ourwork-preview-content'>	<p>{props.summary}</p> </div>
				</div>

			</div>

			<div className='ourwork-card-footer'>
				<span>
					<span className='readmore' />
					<span className='fas fa-chevron-right ourwork-card-icon uk-margin-small-left' />
				</span>
			</div>

		</a>

		<div className='uk-accordion-content uk-padding uk-text-small uk-margin-remove-top'>
			{props.description &&
				<div dangerouslySetInnerHTML={{__html:
        props.description}}></div>
			}

			{props.applyText &&
				<div className='uk-flex uk-flex-right uk-flex-middle uk-margin-small-top'>
					<a className='uk-button uk-button-default' href={props.applyUrl}>{props.applyText}<span className='fas fa-external-link-alt uk-margin-small-left' /></a>
				</div>
			}
				</div>
					</li>
					)
