import React from 'react'
import classNames from 'classnames'

import CollectiveImage from '../../images/cards/featured/collective.jpg'
import GoreImage from '../../images/cards/featured/gore.jpg'
import KaviImage from '../../images/cards/featured/kavi.jpeg'

import Loader from '../templates/Loader';

import PlaceholderParagraph from '../templates/PlaceholderParagraph';

import axios from 'axios'

const NAPCard = (props) => (
    <div className='napc uk-flex uk-flex-column hvr-icon-forward uk-box-shadow-medium uk-box-shadow-hover-large' style={{border: '.5px solid #e5e5e5'}}>
      {
        (props.image ? <img src={props.image} /> : <Loader />)
      }

      <div className='napc-header'>
        <div className='heading'>{props.heading || 'Loading..'}</div>
        <div className='subheading'>{props.source || 'NAPC'}, {props.date || 'January 1st, 1970'}</div>
      </div>

      <div className='napc-body'>
        <p>{props.content || <PlaceholderParagraph /> }</p>
      </div>

      <div className='napc-footer uk-flex uk-flex-center uk-flex-middle'>
        <a target='_blank' className='uk-link-reset' href={props.footerUrl}>Continue Reading<span className='uk-margin-small-left fas fa-chevron-right hvr-icon' /></a>
      </div>
    </div>
)

export default class Featured extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      cards: [<NAPCard />, <NAPCard />, <NAPCard />]
    }
  }

  componentDidMount() {
    axios.get('https://staging-backend.kavibrooklyn.org/api/featured_press?_format=json')
      .then( (res) => {
        let items = res.data.map( (item, index) => (
          <NAPCard
            heading={item.headline}
            source={item.publisher}
            date={item.publish_date}
            image={item.article_image}
            content={item.article_summary}
            footerUrl={item.article_source}
          />
        ))

        this.setState({ loading: false, cards: items})

      }, (err) => console.log(err))
  }

  render() {
    return (<div className='uk-section uk-section-muted kavi-gradient-2'>

      <div className='uk-container'>

        <div className='uk-flex uk-flex-column uk-flex-middle'>
          <h1 className='uk-container-title uk-margin-remove'>Featured</h1>

          <hr className='uk-divider-icon uk-width-2-3'/>
        </div>

        { this.state.loading ? <Loader heightLarge={true}/> :

          <div>
            <div className='uk-hidden@s' data-uk-slider="data-uk-slider">

              <ul className='uk-slider-items uk-child-width-1-1 uk-grid uk-flex-middle'>
                {this.state.cards.map( (card, index) => (
                  <li key={index}>
                    <div style={{margin: '2rem', marginTop: null}}>
                      {card}
                    </div>
                  </li>
                ))}
              </ul>

              <div className='uk-flex uk-flex-center uk-flex-around uk-flex-row'>
                <a href='#' style={{
                    color: '#666'
                }} className='fas fa-chevron-left' onClick={(e) => e.preventDefault()} data-uk-slider-item='previous'/>
                <ul className="uk-slider-nav uk-dotnav"></ul>
                <a href='#' style={{
                    color: '#666'
                }} className='fas fa-chevron-right' onClick={(e) => e.preventDefault()} data-uk-slider-item='next'/>
              </div>

            </div>

            <div className='uk-visible@s uk-child-width-1-3 uk-grid-match' data-uk-grid="data-uk-grid">
              {this.state.cards.map( (card, index) => <div key={index}> {card} </div>)}
            </div>

          </div>

        }

      </div>

    </div>)
  }
}
