import React from 'react'
import classNames from 'classnames'

import Hero from '../../templates/Hero'
import Header from '../../templates/Header'
import Loader from '../../templates/Loader';
import { KaviFooter } from '../../templates/Footer'
import PlaceholderParagraph from '../../templates/PlaceholderParagraph';

let HeroText = <React.Fragment><span>Our</span> Mission</React.Fragment>
import OurMissionBackgroundImage from '../../../images/headers/ourmission.jpg'

import axios from 'axios'

export const MissionStatement = (props) => (
  <div className='uk-box-shadow-medium uk-box-shadow-hover-large uk-margin uk-padding kavi-background-white' style={{border: '.75px solid #e5e5e5'}}>
    <h2 className='uk-text-center kavi-text-poppin-orange' style={{fontWeight: '300', textTransform: 'uppercase'}}>{props.header}</h2>
    { props.content ? <p>{props.content}</p> : <PlaceholderParagraph /> }
  </div>
)

const DefaultMissionStatement = {
  header: 'Loading...',
  content: ''
}

export default class OurMission extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        loading: true,
        missionStatements: [DefaultMissionStatement]
      }
    }

    componentDidMount() {

      axios.get('https://staging-backend.kavibrooklyn.org/api/mission?_format=json')
        .then( (res) => {

          this.setState({ loading: false });

          if (res.status === 200) {
            // todo: this splice is a temporary fix for the invalid entries in the api REMOVE
            let items = res.data.slice(2).map( (item, index) => ({
              header: item.field_mission_highlight_title,
              content: item.field_mission_highlight_desc,
            }))

            this.setState({ missionStatements: items })
          }
        }, (err) => console.log(err))
    }

  render() {
    return(
      <React.Fragment>
        {/* Header */}
        <Header backgroundImage={OurMissionBackgroundImage} height='uk-height-large'>

          { this.state.loading ? <Loader heightLarge={true} /> : <Hero heroText={HeroText} height='100%'/> }

        </Header>

        <div className='uk-section uk-section-muted kavi-gradient-1'>
          <div className='uk-container'>
            <div className='uk-flex uk-flex-column'>
              {
                this.state.missionStatements.map( (statement, index) => (
                  <MissionStatement {...statement} />
                ))
              }
            </div>
          </div>
        </div>

        {/* Footer */}
        {KaviFooter}
      </React.Fragment>
    )
  }
}
