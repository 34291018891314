import React from 'react'

import Header from '../../templates/Header'
import Hero from '../../templates/Hero'
import Loader from '../../templates/Loader';
import { KaviFooter } from '../../templates/Footer'

import OurMissionBackgroundImage from '../../../images/headers/ourmission.jpg'
const HeroText = <React.Fragment>News <span>& </span>Press</React.Fragment>

import CnnLogo from '../../../images/brands/cnn.png'
import HistoryChannelLogo from '../../../images/brands/historychannel.png'
import NowThisLogo from '../../../images/brands/nowthis.png'
import NyTimesLogo from '../../../images/brands/nytimes.png'
import TedResidencyLogo from '../../../images/brands/ted-residency.png'

import axios from 'axios'

const Logos = [
  <img src={NowThisLogo} className='nt-logo' />,
  <img src={CnnLogo} className='cnn-logo' />,
  <img src={NyTimesLogo} className='nyt-logo' />,
  <img src={TedResidencyLogo} className='ts-logo' />,
  <img src={HistoryChannelLogo} className='hc-logo' />
]

import PlaceholderImage from '../../templates/PlaceholderImage'

const NAPCard = (props) => (


    <div className='napc uk-flex uk-flex-column hvr-icon-forward uk-box-shadow-medium uk-box-shadow-hover-large' style={{border: '.5px solid #e5e5e5'}}>
      {
        (props.image ? <img src={props.image} /> : <PlaceholderImage x='1920' y='1080' text='null' />)
      }

      <div className='napc-header'>
        <div className='heading'>{props.heading || 'Loading...'}</div>
        <div className='subheading'>{props.source || 'NAPC'}, {props.date || 'January 1st, 1970'}</div>
      </div>

      <div className='napc-body'>
        <p>{props.content || NAPCardPlaceholderBody}</p>
      </div>

      <div className='napc-footer uk-flex uk-flex-center uk-flex-middle'>
        <a target='_blank' className='uk-link-reset' href={props.footerUrl}>Continue Reading<span className='uk-margin-small-left fas fa-chevron-right hvr-icon' /></a>
      </div>
    </div>
)

export default class NewsAndPress extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      cards: [<NAPCard />, <NAPCard />, <NAPCard />]
    }
  }

  componentDidMount() {
    axios.get('https://staging-backend.kavibrooklyn.org/api/press?_format=json')
      .then( (res) => {
        let items = res.data.map( (item, index) => (
          <NAPCard
            key={index}
            heading={item.headline}
            source={item.publisher}
            date={item.publish_date}
            image={item.article_image}
            content={item.article_summary}
            footerUrl={item.article_source}
          />
        ))

        this.setState({ loading: false, cards: items})

      }, (err) => console.log(err))
  }

  render() {
    return (
      <React.Fragment>
        {/* Header */}
        <Header backgroundImage={OurMissionBackgroundImage} height='uk-height-large'>

          <Hero heroText={HeroText} height='100%'/>
        </Header>

        { this.state.loading ? <Loader heightLarge={true} /> :

          <div className='uk-section kavi-gradient-1' style={{background: 'rgb(240,240,240)'}}>
            <div className='uk-container'>

              <div className='nap-logos uk-flex uk-flex-middle uk-flex-center uk-margin-large uk-width-expand'>
                {Logos.map((logo, item) => <div className='uk-flex uk-flex-around uk-flex-middle'>{logo}</div>)}
              </div>

              <div className='napc-grid uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex uk-flex-center uk-flex-around uk-grid-match' data-uk-grid>
                {this.state.cards.map( (card, index) => <div key={index}> {card} </div>)}
              </div>

            </div>
          </div>

        }

        {/* Footer */}
        {KaviFooter}
      </React.Fragment>
    )
  }
}
