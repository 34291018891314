import React from 'react'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-131554144-1');
ReactGA.pageview(window.location.pathname + window.location.search);

import Navbar from './components/templates/Navbar'

const App = (props) => (
  <Navbar />
)

export default App
