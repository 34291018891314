import "@babel/polyfill"

import React from 'react'
import ReactDOM from 'react-dom'

import './stylesheets/main.scss'

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons.min';

UIkit.use(Icons)

import App from './App'

import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('app')
)
