import React from 'react'
import Image1 from '../../../images/kavi-students-in-discussion.jpg'
import Image2 from '../../../images/kavi-school-program-in-session.JPG'

export default (props) => (
  <div>
    <h2 className="uk-padding uk-padding-remove-vertical uk-text-center">KAVI goes to school to help youth prevent violence, heal from trauma, and navigate growing up.</h2>
    <hr className="uk-divider-icon uk-padding uk-padding-remove-vertical" />
    <div className="uk-padding" data-uk-grid>

      <div className="uk-width-1-2@m">
      <img src={Image1} className="uk-border-rounded" alt="Dr. Rob Gore in a KAVI session." />
      </div>
      <div className="uk-width-1-2@m">
        <p>
          The classrooms, hallways, cafeterias, and gyms of our schools should be safe sanctuaries for our young people - free of the violence and conflicts on the streets of our communities. They should be safe spaces where young people learn, grow, socialize, thrive, and come into their own as happy adults - and should leave with a sense of accomplishment, clearly focused on building lives of successful families, careers and fulfilment.
        </p>
        <p>The reality is that young people often bring conflicts and social/emotional concerns from their homes and communities into school with them.</p>
        <p>Helping our students and schools create a safe and nurturing learning environment goes beyond academics and acquiring skills. It includes the tools and strategies to be able engage in productive relationships with teachers, staff, and other authorities, peers, mentors, and friends.</p>
        <p>To help create this nurturing environment and in response to the high numbers of youth being seen for intentional injuries in the KCHC Emergency Department, KAVI offers violence prevention and intervention programs and curriculum in local high schools.</p>
      </div>

      <div className="uk-width-1-1@m">
        <p>
          All young people in the schools where we work are invited to participate in <b>KAVI Queens and KAVI Kings</b>, though we focus our programming on youth directly impacted by violence or who may be at-risk of being impacted by violence. Many of the youth have reveal dangerous behaviors and the likelihood for engaging in future violent activity. Therefore, KAVI’s prevention services in the schools, include workshops for “safe spaces” to talk about the issues and influences that led to violence and to consider alternatives.
        </p>
        <p>
          Our school-based program includes a 3-week module, group-level intervention that takes a deep look at the many ways that youth were experiencing violence through race, gender, and other structural issues. This led to the inclusion of training modules on <b>racial and social justice, Restorative Justice, and trauma-informed care</b> to address the deeper psychosocial impacts of violence with our youth to our curriculum. KAVI students now have parallel school-based youth programs- one for young men and one for young women.
        </p>
        <p>
        The focus is to enable participants achieve their potential, strengthen their sense of self and handle conflict more readily so they can do better in school, succeed in their careers, and create lasting positive change in their communities.
        </p>
        <p>Our programs, provided through the school year, our peer facilitators and coordinator help our student to...
          <ul>
            <li>Get the support they need to succeed in school.</li>
            <li>Make better choices in their relationships with friends and family to be able to stay safe and walk away from violence.</li>
            <li>Access a more supportive school environment that offers opportunities to encourage their passions, dreams, and careers.</li>
          </ul>
        </p>
      </div>

      <div className="uk-width-1-2@m">
        <p>
          Our curriculum uses a group-based learning approach where young women and men learn from each other’s experiences and problem solve together to overcome challenges, handle difficult social and family situations, and take steps to realize their dreams and passions.
        </p>
        <p>
          Our skilled peer facilitators come from the neighborhoods where our young people live. We strongly believe that solutions to public safety and violence comes directly from the communities most impacted. Young people involved in our programming may later become on-staff peer facilitators themselves.
        </p>
        <p>
          We integrate restorative justice practices to hold young people accountable for their actions while keeping them in community and helping them heal.
        </p>
        <p>We believe restorative justice practices are an important part of building a more supportive school environment. KAVI team members advocate on behalf of young people to school staff, in disciplinary meetings and with parents.</p>
      </div>
      <div className="uk-width-1-2@m">
      <img src={Image2} className="uk-border-rounded" alt="KAVI students gathered." />
      </div>
    </div>
    <hr className="uk-divider-icon" />

  </div>
)
