import React from 'react'

const KAVI_FOOTER = {
  heading: 'KAVI | Kings Against Violence Initiative',
  address: '147 Prince Street, Ste. 416, Brooklyn, NY 11201',
  email: 'info@kavibrooklyn.org',
  phone: '(917) 728-1147',
  copyright: 'Copyright © 2023 Kings Against Violence Initiative. All Rights Reserved.',
}

const Footer = (props) => {
  const { heading, address, email, phone, copyright } = props;

  return (
    <div className='uk-section kavi-background-poppin-orange'>
      <div className='uk-container'>
        <div className='uk-visible@s uk-light'>
          <div className='uk-flex uk-flex-between'>

            <h3 className="uk-text-bold uk-text-uppercase kavi-text-white kavi-letter-spacing-1 uk-margin-remove">{heading}</h3>
            <div className='uk-width-1-6 uk-flex uk-flex-middle uk-flex-around'>
              <a href='https://www.facebook.com/kingsagainstviolenceinitiative/' target="_blank"><span className='fab fa-facebook-square fa-lg'/></a>
              <a href='https://www.instagram.com/kavibrooklyn/' target="_blank"><span className='fab fa-instagram fa-lg'/></a>
              <a href='https://twitter.com/kavi_brooklyn' target="_blank"><span className='fab fa-twitter-square fa-lg'/></a>
            </div>

          </div>


          <div className='uk-flex uk-flex-between uk-margin-medium-top'>
            <div className='uk-list'>
              <ul className="uk-text-left uk-text-meta uk-text-uppercase uk-padding-remove">
                <li className="uk-margin-small-bottom">
                  <a href="https://goo.gl/maps/AGZPcy3Y8W8NwBoZ6" alt="Open a Google Map of KAVI location." target="_blank">
                  <span className="fa fa-map-marker fa-lg uk-margin-small-right"></span>
                    {address}
                  </a>
                </li>

                <li className="uk-margin-small-bottom">
                  <a href="mailto:info@kavibrooklyn.org" alt="Send an e-mail to KAVI." target="_blank">
                  <span className="fa fa-envelope fa-lg uk-margin-small-right"></span>
                    {email}
                  </a>
                </li>
                <li className="uk-margin-small-bottom">
                  <a href="tel:+19177281147" alt="Call KAVI." target="_blank">
                  <span className="fa fa-phone fa-lg uk-margin-small-right"></span>
                    {phone}
                  </a>
                </li>
              </ul>
            </div>

            <p className="uk-text-small uk-text-meta uk-margin-remove uk-margin-auto-top uk-text-uppercase uk-text-right">{copyright}</p>
          </div>
        </div>

        <div className='uk-hidden@s uk-light uk-text-uppercase uk-text-center kavi-text-white uk-flex uk-flex-column uk-flex-middle'>
          <h3 className="uk-text-bold uk-text-uppercase kavi-letter-spacing-1">{heading}</h3>

          <div className='uk-width-2-3 uk-text-lead uk-text-small'>
            <hr />

            <p className='uk-margin-remove'>{address}</p>
            <p className='uk-margin-remove'>{email}</p>
            <p className='uk-margin-remove-top'>{phone}</p>

            <div className='uk-flex uk-flex-center uk-flex-middle uk-flex-around'>
              <a href='https://www.facebook.com/kingsagainstviolenceinitiative/' target="_blank"><span className='fab fa-facebook-square fa-2x'/></a>
              <a href='https://www.instagram.com/kavibrooklyn/' target="_blank"><span className='fab fa-instagram fa-2x'/></a>
              <a href='https://twitter.com/kavi_brooklyn' target="_blank"><span className='fab fa-twitter-square fa-2x'/></a>
            </div>

            <hr />
          </div>

          <p className="uk-text-small uk-text-meta uk-margin-remove" style={{fontSize: '.6rem'}}>{copyright}</p>
        </div>
      </div>
    </div>
  )
};

export const KaviFooter = <Footer {...KAVI_FOOTER} />;

export default Footer;
