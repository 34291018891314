import React from 'react'

export default (props) => (
<div className="kavi-gradient-1 kavi-gradient-top">
  <div className="uk-text-justify uk-padding-large">
    <div className="uk-margin-medium-bottom" data-uk-grid>
      <div className="uk-width-1-3@m">
        <h2 className='uk-container-title'>Our Mission</h2>
        <hr className='uk-width-2-3 uk-margin-auto' />
      </div>
      <div className="uk-width-2-3@m">
          <p className="uk-text-medium uk-text-bold">
            Kings Against Violence Initiative, Inc. (KAVI) is a nonprofit, 501(c)(3) youth-serving organization with a mission to prevent and eliminate interpersonal violence from the lives of young people through advocacy, peer leadership, community mobilization, and social justice.
            <br/><br/>
            KAVI operates youth violence interventions programs in Central Brooklyn.  Our programs take place in schools and hospital-based settings.  We also work with a broad range of partners to do community strengthening and healing programs.
          </p>
      </div>
    </div>
    <hr className='uk-divider-icon' />
    <div className="uk-margin-medium-top" data-uk-grid>
      <div className="uk-width-1-3@m">
        <h2 className='uk-container-title'>A PUBLIC HEALTH APPROACH TO ENDING VIOLENCE</h2>
        <hr className='uk-width-2-3 uk-margin-auto' />
      </div>
      <div className="uk-width-2-3@m">
        <p className="uk-text-medium">
          Violence is an endemic public health issue, particularly in low-income and communities of color. Communities of color in Central Brooklyn are plagued by violence, poverty and crime at an alarming rate. Gun violence, as just one example of violence, is the leading cause of death amongst 15-24 year-olds. However, Central Brooklyn is not unique; there are communities around the country experiencing loss and neglect of young people due to violence.
          <br/><br/>
          Violence not only takes on many different forms, but our public health perspective recognizes many different factors contribute to violence, including lack of access to a quality and supportive education, poor mental health and growing up in neighborhoods with limited resources and services. Our young people are both the victims and perpetrators of violence. Resorting to violence is often both the symptom and cause of longstanding and systemic inequities, poverty, and marginalization. Hurt people hurt people.
        </p>
      </div>
    </div>
  </div>
</div>
)
